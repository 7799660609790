import React, { useEffect, useState } from "react"
import {
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  DialogActions,
  Button,
  IconButton,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
} from "@mui/material"
import { ExpandMore, ExpandLess } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { useQuery, useMutation } from "@tanstack/react-query"
import { useForm, Controller } from "react-hook-form"
import { useToast } from "../../contexts"
import {
  getAvailableFootersAPI,
  getEmailConfigurationsAPI,
  updateEmailConfigurationsAPI,
} from "../../services"
import { colors, decodeHtml } from "../../utils"
import { StyledCheckbox } from "../UpdateGroupModal/styled"

export const CommunicationSettings: React.FC<{
  organizationData: any
  onClose: () => void
  isOrganization: boolean
}> = ({ organizationData, isOrganization, onClose }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [expandedConfig, setExpandedConfig] = useState<number | null>(null)

  const { control, reset, handleSubmit, watch, formState } = useForm<{
    configurations: IEmailConfiguration[]
  }>({
    defaultValues: {
      configurations: [],
    },
  })

  const { data: emailConfigurations, isLoading: isLoadingConfigurations } =
    useQuery({
      queryKey: ["email-configurations", organizationData.id],
      queryFn: () => getEmailConfigurationsAPI(organizationData.id),
      refetchOnMount: true,
    })

  const { data: availableFooters, isLoading: isLoadingFooters } = useQuery({
    queryKey: ["available-footers"],
    queryFn: getAvailableFootersAPI,
    refetchOnMount: true,
  })

  const { mutate: updateSettings, isPending: isSaving } = useMutation({
    mutationFn: (data: IUpdateEmailConfigurationRequestParams) =>
      updateEmailConfigurationsAPI(data),
    onSuccess: () => {
      toast.show(t("communicationSettingsUpdated"), "success")
      onClose()
    },
    onError: () => {
      toast.show(t("errorUpdatingCommunicationSettings"), "error")
    },
  })

  useEffect(() => {
    if (emailConfigurations) {
      reset({ configurations: emailConfigurations })
    }
  }, [emailConfigurations, reset])

  const handleToggleExpand = (index: number) => {
    setExpandedConfig((prev) => (prev === index ? null : index))
  }

  const onFormSubmit = (data: { configurations: IEmailConfiguration[] }) => {
    const requestPayload: IUpdateEmailConfigurationRequestParams = {
      id: organizationData.id,
      body: data.configurations.map(
        ({ isConfigEnabled, canEmailBeDisabled, footer, ...rest }) => ({
          ...rest,
        }),
      ),
    }

    updateSettings(requestPayload)
  }

  if (isLoadingConfigurations || isLoadingFooters)
    return (
      <Box display="flex" flexDirection="column" gap="16px" padding="16px">
        {[...Array(3)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            height={50}
            sx={{ marginBottom: "16px" }}
          />
        ))}
      </Box>
    )

  const configurations = watch("configurations")

  const getConfigHtml = (index: number) =>
    availableFooters?.find(
      (f) => f.footerKey === configurations?.[index]?.footerKey,
    )?.footer

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      {configurations.map((config, index) => {
        const configFooter = getConfigHtml(index)

        return (
          <Box
            key={index}
            border={`1px solid ${
              formState.errors.configurations?.[index]
                ? `${colors.red}`
                : `${colors.gray2}`
            }`}
            borderRadius="8px"
            padding="16px"
            position="relative"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleToggleExpand(index)}
              className="pointer"
            >
              <Typography variant="regular">{t(config.emailType)}</Typography>
              <IconButton>
                {expandedConfig === index ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>

            <Collapse in={expandedConfig === index}>
              <Box display="flex" flexDirection="column" gap="16px" mt={2}>
                {!isOrganization && (
                  <Controller
                    name={`configurations.${index}.isOverwritingOrganizationSettings`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        label={t("overwriteOrganizationSettings")}
                        labelPlacement="end"
                      />
                    )}
                  />
                )}
                {(isOrganization ||
                  config.isOverwritingOrganizationSettings) && (
                  <>
                    {config.canEmailBeDisabled && (
                      <Controller
                        name={`configurations.${index}.isEmailEnabled`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                {...field}
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                            label={t("emailEnabled")}
                          />
                        )}
                      />
                    )}

                    <Controller
                      name={`configurations.${index}.senderEmail`}
                      control={control}
                      rules={{
                        required: t("required"),
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth>
                          <InputLabel>{t("senderEmail")}</InputLabel>
                          <Select
                            {...field}
                            label={t("senderEmail")}
                            fullWidth
                            error={!!error}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            <MenuItem value="noreply@crashmate.de">
                              noreply@crashmate.de
                            </MenuItem>
                            <MenuItem value="noreply@west.cloud">
                              noreply@west.cloud
                            </MenuItem>
                          </Select>
                          {error && (
                            <Typography color="error">
                              {error.message}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    />

                    <FormControl fullWidth variant="outlined">
                      <InputLabel>{t("selectFooter")}</InputLabel>
                      <Controller
                        name={`configurations.${index}.footerKey`}
                        control={control}
                        rules={{
                          required: t("required"),
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            label={t("selectFooter")}
                            fullWidth
                            error={!!error}
                            onChange={(e) => {
                              const selectedFooterKey = e.target.value

                              field.onChange(selectedFooterKey)
                            }}
                          >
                            {availableFooters?.map((footer) => (
                              <MenuItem
                                key={footer.footerKey}
                                value={footer.footerKey}
                              >
                                {t(footer.footerKey)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>

                    <Typography variant="regularBold">
                      {t("footerPreview")}
                    </Typography>
                    <Box
                      border={`1px solid ${`${colors.gray2}`}`}
                      p={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {configFooter ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(configFooter),
                          }}
                        />
                      ) : (
                        <Typography>{t("noFooterData")}</Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Collapse>
          </Box>
        )
      })}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton variant="contained" type="submit" loading={isSaving}>
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}
