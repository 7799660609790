import { Box, Select, styled } from "@mui/material"

export const SelectDropdown = styled(Select)`
  width: 180px;

  // & .MuiOutlinedInput-input {
  //   padding: 0 12px;
  //   font-size: 14px;
  // }
`

export const ItemBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
