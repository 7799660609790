import React, { type MouseEventHandler, useCallback, useState } from "react"
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { colors } from "../../../../utils"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { SubGroupsManagement } from "../SubGroupsManagement"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import dayjs from "dayjs"

interface IProps {
  group: IGroup
  hasPermissionToChangeAllowedGroups?: boolean
  onAddSubGroupClick?: (group: IGroup) => void
  onEditClick: (group: IGroup) => void
  onDeleteClick: (group: IGroup) => void
  onAllowedGroupsClick: (group: IGroup) => void
}

export const GroupBodyRow = (props: IProps) => {
  const {
    group,
    hasPermissionToChangeAllowedGroups,
    onAddSubGroupClick,
    onEditClick,
    onDeleteClick,
    onAllowedGroupsClick,
  } = props
  const disabled = !group.isEnabled
  const [isExpanded, setExpanded] = useState(false)

  const handleExpand: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      setExpanded((prev) => !prev)
    },
    [],
  )

  const handleEdit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      onEditClick(group)
    },
    [group],
  )

  const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      onDeleteClick(group)
    },
    [group],
  )

  const handleAllowedGroups: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      onAllowedGroupsClick(group)
    },
    [group],
  )

  return (
    <React.Fragment>
      <TableRow
        sx={{ bgcolor: disabled ? colors.gray : "inherit" }}
        onClick={() => setExpanded((prev) => !prev)}
        className="pointer"
      >
        <TableCell>
          <IconButton onClick={handleExpand}>
            {isExpanded ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography
            className="line-clamp-2"
            color={colors.gray9}
            variant="small"
          >
            {group.name}
          </Typography>
        </TableCell>
        <TableCell>
          {group.createdAt
            ? dayjs(group.createdAt).format("DD.MM.YYYY HH:mm")
            : ""}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" gap="16px">
            {!!hasPermissionToChangeAllowedGroups && (
              <IconButton onClick={handleAllowedGroups}>
                <PlaylistAddIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton onClick={handleEdit}>
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "auto !important" }}>
        <TableCell
          style={{ padding: 0, borderBottom: !isExpanded ? "none" : undefined }}
          colSpan={4}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <SubGroupsManagement
              group={group}
              hasPermissionToChangeAllowedGroups={
                hasPermissionToChangeAllowedGroups
              }
              onAddSubGroupClick={onAddSubGroupClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onAllowedGroupsClick={onAllowedGroupsClick}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
