import { type ChangeEventHandler } from "react"
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import SearchIcon from "@mui/icons-material/Search"
import AddIcon from "@mui/icons-material/Add"

interface IProps {
  onNewVehicleClick: () => void
  onSearchChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const VehicleHeader = (props: IProps) => {
  const { onSearchChange, onNewVehicleClick } = props
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))

  return (
    <Box
      display="flex"
      gap={isSmallerThanLg ? "16px" : "24px"}
      flexWrap="wrap"
      paddingX="24px"
      paddingTop="24px"
      justifyContent="space-between"
    >
      <Typography variant="h4">{t("vehicleManagement")}</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={isSmallerThanLg ? "8px" : "16px"}
      >
        <Box>
          <TextField
            variant="outlined"
            placeholder={t("search")}
            fullWidth={isSmallerThanLg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={onSearchChange}
          />
        </Box>
        <Button
          className="whitespace-no-wrap"
          onClick={onNewVehicleClick}
          startIcon={<AddIcon />}
        >
          {t("newVehicle")}
        </Button>
      </Box>
    </Box>
  )
}
