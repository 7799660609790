import {
  Controller,
  type FieldValues,
  type RegisterOptions,
  type UseFormReturn,
} from "react-hook-form"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs, { type Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { memo } from "react"

interface IProps {
  label?: string
  controlName: string
  isDisabled?: boolean
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined
  formMethods: UseFormReturn<any, any, undefined>
  maxDate?: Dayjs
  onFieldBlur?: (field: IChangedField) => void
  onFieldFocus?: () => void
}

export const DateController = memo((props: IProps) => {
  const {
    label,
    controlName,
    rules,
    isDisabled,
    formMethods,
    maxDate,
    onFieldFocus,
    onFieldBlur,
  } = props
  const { t } = useTranslation()
  const { control, clearErrors, setError } = formMethods

  return (
    <Controller
      control={control}
      name={controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <DatePicker
          className="width-100"
          label={label}
          format="DD.MM.YYYY"
          value={value ? dayjs(value) : null}
          onOpen={() => {
            onFieldFocus?.()
          }}
          onChange={(value) => {
            const nextValue = value?.isValid()
              ? value.format("YYYY-MM-DD")
              : null
            onChange(nextValue)
            onFieldBlur?.({
              controlName,
              value: nextValue,
            })
          }}
          onClose={onBlur}
          disabled={isDisabled}
          slotProps={{
            textField: {
              error: !!error?.message,
              helperText: error?.message,
              onBlur,
            },
          }}
          maxDate={maxDate}
          onError={(e) => {
            if (!e) {
              clearErrors(controlName)
            } else if (e === "invalidDate") {
              setError(controlName, {
                message: t("invalidDateFormatErrorMessage"),
              })
            }
          }}
        />
      )}
    />
  )
})
