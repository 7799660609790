import { useCallback, useState } from "react"
import { debounce } from "lodash"
import { Box } from "@mui/material"
import { colors } from "../../utils/colors"
import { AUTHORIZED_CONTENT_MAX_WIDTH } from "../../utils"

import {
  CreateVehicleDialog,
  VehicleHeader,
  VehiclesListTable,
} from "./components"
import { AlertDialog } from "../../components"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteVehicleAPI } from "../../services"
import { useToast } from "../../contexts"

export const VehicleManagementPage = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState("")
  const [isCreateVehicleDialogVisible, setCreateVehicileDialogVisible] =
    useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | undefined>(
    undefined,
  )
  const [isDeleteVehicleDialogVisible, setDeleteVehicleDialogVisible] =
    useState(false)

  const queryClient = useQueryClient()

  const toast = useToast()

  const { mutate: deleteVehicle, isPending: isVehicleDeleting } = useMutation({
    mutationFn: (id: string) => deleteVehicleAPI(id),
    onSuccess: () => {
      toast.show(t("vehicleDeletedSuccessfullyMessage"), "success")

      void queryClient.refetchQueries({ queryKey: ["vehicles"] })
    },
    onSettled: () => {
      setDeleteVehicleDialogVisible(false)
      setSelectedVehicle(undefined)
    },
  })

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearch(text)
    }, 350),
    [],
  )

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value)
    },
    [],
  )

  const onNewVehicleClick = useCallback(
    () => setCreateVehicileDialogVisible(true),
    [],
  )

  const onCloseCreateVehicleDialog = useCallback(() => {
    setCreateVehicileDialogVisible(false)
    setSelectedVehicle(undefined)
  }, [])

  const onEditVehicleClick = useCallback((vehicle: any) => {
    setCreateVehicileDialogVisible(true)
    setSelectedVehicle(vehicle)
  }, [])

  const onDeleteVehicleClick = useCallback((vehicle: any) => {
    setDeleteVehicleDialogVisible(true)
    setSelectedVehicle(vehicle)
  }, [])

  const handleConfirmDelete = useCallback(() => {
    deleteVehicle(selectedVehicle?.id!)
  }, [selectedVehicle])

  const handleCancelDelete = useCallback(() => {
    setDeleteVehicleDialogVisible(false)
    setSelectedVehicle(undefined)
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        gap="8px"
      >
        <CreateVehicleDialog
          isVisible={isCreateVehicleDialogVisible}
          selectedVehicle={selectedVehicle}
          onClose={onCloseCreateVehicleDialog}
        />
        <AlertDialog
          isVisible={isDeleteVehicleDialogVisible}
          message={t("deleteDialogConfiratiomMessage", {
            registrationNumber: selectedVehicle?.numberPlate,
          })}
          confirmLabel={t("delete")}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          loading={isVehicleDeleting}
        />
        <VehicleHeader
          onSearchChange={handleSearch}
          onNewVehicleClick={onNewVehicleClick}
        />
        <Box flex={1} display="grid" paddingBottom="24px" marginTop="24px">
          <VehiclesListTable
            search={search}
            onEditVehicleClick={onEditVehicleClick}
            onDeleteVehicleClick={onDeleteVehicleClick}
          />
        </Box>
      </Box>
    </Box>
  )
}
