import React, { useState, useEffect, useMemo } from "react"
import { Dialog, DialogTitle, DialogContent, Tabs, Tab } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GroupGeneralSettings } from "./components"
import { CommunicationSettings } from "../CommunicationSettings"
import { useQuery } from "@tanstack/react-query"

interface IProps {
  isVisible: boolean
  groupData?: IGroup
  onClose: () => void
}

const UpdateGroupModal: React.FC<IProps> = (props: IProps) => {
  const { isVisible, groupData, onClose } = props
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const handleTabChange = (_: any, newValue: number) => {
    setActiveTab(newValue)
  }

  const hasPermissionForCommunicationSettings = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "EMAIL_CONFIG" && p.access === "Delete",
      ),
    [user],
  )

  useEffect(() => {
    if (!isVisible) {
      setActiveTab(0)
    }
  }, [isVisible])

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>{t("editGroup")}</DialogTitle>
      {hasPermissionForCommunicationSettings && (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ marginLeft: "20px" }}
          >
            <Tab label={t("generalSettings")} />
            <Tab label={t("communicationSettings")} />
          </Tabs>
        </>
      )}

      <DialogContent>
        {activeTab === 0 && (
          <GroupGeneralSettings
            groupData={groupData}
            onClose={onClose}
            isVisible={isVisible}
          />
        )}
        {activeTab === 1 && hasPermissionForCommunicationSettings && (
          <CommunicationSettings
            organizationData={groupData}
            isOrganization={false}
            onClose={onClose}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default UpdateGroupModal
