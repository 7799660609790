import { TableRow, styled } from "@mui/material"
import { colors } from "../../../../../../../../utils"

interface IRowProps {
  disabled?: boolean
}

export const TableRowStyled = styled(TableRow)<IRowProps>(({ disabled }) => ({
  borderRadius: "10px",
  transition: "all 0.2s ease-in-out",

  "& .MuiTableCell-root": {
    padding: "0px !important",

    transition: "all 0.2s ease-in-out",
    borderTop: `1px solid ${colors.gray10}`,
    borderBottom: `1px solid ${colors.gray10} !important`,
    // "&:nth-of-type(n+5)": {
    //   borderLeft: `1px solid ${colors.gray10}`,
    // },
    "&:first-of-type": {
      borderLeft: `1px solid ${colors.gray10}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      paddingLeft: "6px !important",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${colors.gray10}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },

    "& .MuiTypography-root": {
      lineHeight: "24px",
    },
  },

  "&:hover": {
    "&.MuiTableRow-root": {
      transform: disabled ? "none" : "scale(1.01)",
    },
    "& .MuiTableCell-root": {
      // cursor: disabled ? "auto" : "pointer",
      borderTop: `1px solid ${disabled ? colors.gray10 : colors.primary}`,
      borderBottom: `1px solid ${
        disabled ? colors.gray10 : colors.primary
      } !important`,

      "&:first-of-type": {
        borderLeft: `1px solid ${disabled ? colors.gray10 : colors.primary}`,
      },
      "&:last-of-type": {
        borderRight: `1px solid ${disabled ? colors.gray10 : colors.primary}`,
      },
    },
  },
}))
