import { TableCell, IconButton, Skeleton, Typography, Box } from "@mui/material"
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined"
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import dayjs from "dayjs"
import { colors, VEHICLE_TYPE_OPTIONS } from "../../../../../../../../utils"
import { TableRowStyled } from "./styled"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined"
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

interface IProps {
  isLoading: boolean
  row: IVehicle
  isDisabled?: boolean
  onEditClick?: (vehicle: IVehicle) => void
  onDeleteClick?: (vehicle: IVehicle) => void
}

export const VehicleTableRow = (props: IProps) => {
  const { isLoading, row, isDisabled, onEditClick, onDeleteClick } = props
  const { t } = useTranslation()

  const vehicleType = useMemo(() => {
    if (row.vehicleType === "Andere") {
      return row.vehicleTypeOther
    }

    const type = VEHICLE_TYPE_OPTIONS.find((o) => o.value === row.vehicleType)

    if (type) {
      return t(type.label)
    }

    return null
  }, [row])

  return (
    <TableRowStyled
      disabled={isDisabled}
      className={isDisabled ? "disabled-effect" : ""}
    >
      <TableCell className="word-break-all">
        <Box
          display="flex"
          flexDirection="column"
          paddingX="8px"
          paddingY="6px"
        >
          <Box display="flex" alignItems="center">
            <DirectionsCarFilledOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.numberPlate?.trim() || "-"}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginTop="4px">
            <DirectionsCarFilledOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.vin?.trim() || "-"}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell className="word-break-all">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          paddingX="8px"
          paddingY="6px"
        >
          <Box display="flex" alignItems="center">
            <DirectionsCarFilledOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.manufacturer?.trim() || "-"}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginTop="4px">
            <DirectionsCarFilledOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.manufacturerType?.trim() || "-"}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell className="word-break-all">
        <Box
          display="flex"
          flexDirection="column"
          paddingX="8px"
          paddingY="6px"
        >
          <Box display="flex" alignItems="center">
            <DirectionsCarFilledOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {vehicleType?.trim() || "-"}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginTop="4px">
            <CalendarMonthOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.registrationDate?.trim()
                  ? dayjs(row.registrationDate).format("DD.MM.YYYY")
                  : "-"}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell className="word-break-all">
        <Box
          display="flex"
          flexDirection="column"
          paddingX="8px"
          paddingY="6px"
        >
          <Box display="flex" alignItems="center">
            <ApartmentOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.insuranceCompany?.trim() || "-"}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginTop="4px">
            <GroupsOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.group?.trim() || "-"}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell className="word-break-all">
        <Box
          display="flex"
          flexDirection="column"
          paddingX="8px"
          paddingY="6px"
        >
          <Box display="flex" alignItems="center">
            <CalendarMonthOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.createdAt?.trim()
                  ? dayjs(row.createdAt).format("DD.MM.YYYY")
                  : "-"}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginTop="4px">
            <PeopleOutlineOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.createdBy?.trim() || "-"}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell className="word-break-all">
        <Box
          display="flex"
          flexDirection="column"
          paddingX="8px"
          paddingY="6px"
        >
          <Box display="flex" alignItems="center">
            <CalendarMonthOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.lastUpdatedAt?.trim()
                  ? dayjs(row.lastUpdatedAt).format("DD.MM.YYYY")
                  : "-"}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginTop="4px">
            <PeopleOutlineOutlinedIcon fontSize="small" />
            {isLoading ? (
              <Box
                marginLeft="4px"
                flex={1}
                height="24px"
                display="flex"
                alignItems="center"
              >
                <Skeleton width="70%" />
              </Box>
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {row.lastUpdatedBy?.trim() || "-"}
              </Typography>
            )}
          </Box>
        </Box>
      </TableCell>

      <TableCell align="right">
        <Box display="flex" alignItems="center" gap="8px" paddingX="8px">
          <IconButton onClick={() => onEditClick?.(row)}>
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => onDeleteClick?.(row)}>
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRowStyled>
  )
}
