import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import {
  Body,
  FormSection,
  Header,
  Wrapper,
  SubHeader,
  SubSection,
  CustomBackdrop,
  SelectButton,
  ProgressLine,
} from "./styled"
import { Trans, useTranslation } from "react-i18next"
import { Check, Error } from "@mui/icons-material"
import {
  camelCaseControlName,
  checkAllConditionsVisibility,
  colors,
  filterObjectByFalsy,
} from "../../../../utils"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getClaimComparisonAPI,
  syncAdwovareAPI,
  updateClaimAPI,
} from "../../../../services"
import { useAppContext, useToast } from "../../../../contexts"
import { LoadingButton } from "@mui/lab"
import { SectionsList } from "../SectionsList"
import { useNavigate } from "react-router-dom"

interface IProps {
  open: boolean
  claimId: string
  onClose: () => void
  claimTemplate: IClaimTemplate
}

export const ClaimComparisonModal = (props: IProps) => {
  const { claimTemplate, open, claimId, onClose } = props
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const { breakpoints } = useTheme()
  const isSmallerThanSm = useMediaQuery(breakpoints.down("sm"))
  const [selectedVersion, setSelectedVersion] = useState<
    "advoWareVersion" | "crashMateVersion" | undefined
  >(undefined)
  const form1Ref = useRef<HTMLDivElement>(null)
  const form2Ref = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(
    (
      sourceRef: React.RefObject<HTMLDivElement>,
      targetRef: React.RefObject<HTMLDivElement>,
    ) => {
      if (sourceRef.current && targetRef.current) {
        const { scrollTop, scrollLeft } = sourceRef.current
        targetRef.current.scrollTop = scrollTop
        targetRef.current.scrollLeft = scrollLeft
      }
    },
    [],
  )

  const handleForm1Scroll = useCallback(() => {
    handleScroll(form1Ref, form2Ref)
  }, [form1Ref, form2Ref])

  const handleForm2Scroll = useCallback(() => {
    handleScroll(form2Ref, form1Ref)
  }, [form1Ref, form2Ref])

  useEffect(() => {
    if (form1Ref.current && form2Ref.current) {
      form1Ref.current.addEventListener("scroll", handleForm1Scroll)
      form2Ref.current.addEventListener("scroll", handleForm2Scroll)
    }
    return () => {
      if (form1Ref.current && form2Ref.current) {
        form1Ref.current.removeEventListener("scroll", handleForm1Scroll)
        form2Ref.current.removeEventListener("scroll", handleForm2Scroll)
      }
    }
  }, [])

  const { state: appState } = useAppContext()

  const {
    data: claimData,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["claim-comparison", claimId],
    queryFn: () => getClaimComparisonAPI(claimId),
    enabled: open,
  })

  const { refetch: refetchClaimDetails } = useQuery({
    queryKey: ["claim-details", claimData?.id],
    enabled: false,
  })

  const { mutate: updateClaim, isPending: isClaimUpdating } = useMutation({
    mutationFn: (requestParams: IUpdateClaimRequestParams) =>
      updateClaimAPI(requestParams),
    onSuccess: () => {
      toast.show(t("claimUpdatedSuccessfully"), "success")
      navigate("/")
      onClose()
    },
  })

  const { mutate: syncAdwovare, isPending: isAdwovareSyncing } = useMutation({
    mutationFn: (force: boolean) =>
      syncAdwovareAPI({
        id: claimData?.id!,
        params: { isDuplicatePassed: !!force },
      }),
    onSuccess: (response: string) => {
      toast.show(response, "success")
      navigate("/")
      onClose()
    },
  })

  const methodsAdvoware = useForm({
    values: claimData?.claimDetails,
  })

  const { setValue: setAdvoWareClaimValue, getValues: getAdvoWareClaimValues } =
    methodsAdvoware

  const methodsCrashMate = useForm({
    values: claimData?.claimDetails,
  })

  const onClickResolve = useCallback(() => {
    if (selectedVersion === "advoWareVersion") {
      claimTemplate?.steps
        ?.map((s) => s.sections)
        ?.flat()
        ?.map((s) => s.subSections)
        ?.flat()
        ?.map((s) => s.metadatas)
        ?.flat()
        ?.filter((m) => !!m?.conditions?.length)
        ?.forEach((m) => {
          const metadataValue = getAdvoWareClaimValues(m.controlName)

          if (!metadataValue) {
            return
          }

          const isVisible = checkAllConditionsVisibility(
            m.conditions,
            getAdvoWareClaimValues,
          )

          if (isVisible) {
            return
          }

          setAdvoWareClaimValue(m.controlName, null)
        })

      const values = getAdvoWareClaimValues()
      const filteredValues = filterObjectByFalsy(values)

      const requestParams: IUpdateClaimRequestParams = {
        id: claimData?.id!,
        body: filteredValues ?? {},
        params: {
          isSavingAsDraft: false,
          isSyncronizingFromAdvoware: true,
          lastSection: claimData?.lastActiveSection!,
          groupId: appState?.groupId!,
        },
      }
      updateClaim(requestParams)
    }

    if (selectedVersion === "crashMateVersion") {
      syncAdwovare(true)
    }
  }, [selectedVersion, claimData, appState, getAdvoWareClaimValues])

  const showAdvoWareChanges = useCallback(() => {
    claimData?.comparisonDifferences?.forEach((difference) => {
      setAdvoWareClaimValue(
        camelCaseControlName(difference.key),
        difference.value,
      )
    })
  }, [claimData])

  const claimTemplateFilteredByPermissions = useMemo(() => {
    if (claimData && claimTemplate) {
      // const hasPermissionToViewAllSections = claim.permissions?.some(
      //   (p) =>
      //     p.action === "NONE" &&
      //     p.claimSectionType === "None" &&
      //     p.access !== "None",
      // )

      return {
        ...claimTemplate,
        steps: claimTemplate?.steps
          ?.filter(
            (s) =>
              claimData.permissions.some(
                (p) => p.stepType === s.stepType && p.access !== "None",
              ) ||
              !claimData.permissions.some((p) => p.stepType === s.stepType),
          )
          ?.map((step) => {
            return {
              ...step,
              // sections: hasPermissionToViewAllSections
              //   ? step.sections
              //   : step?.sections?.filter(
              //       (section) =>
              //         claim.permissions?.some(
              //           (p) =>
              //             p.action === "NONE" &&
              //             p.claimSectionType === section.sectionType &&
              //             p.access !== "None",
              //         ),
              //     ),
              sections: step?.sections?.filter(
                (section) => !section?.isDocumentSection,
              ),
            }
          }),
      }
    }
  }, [claimData, claimTemplate])

  const conflictedSectionIds = useMemo(() => {
    const conflictedControlNames = claimData?.comparisonDifferences?.map((c) =>
      camelCaseControlName(c.key),
    )
    return claimTemplateFilteredByPermissions?.steps
      ?.map((s) => s.sections)
      ?.flat()
      ?.map((s) => s.subSections)
      ?.flat()
      ?.filter(
        (s) =>
          s?.metadatas?.some(
            (c) => conflictedControlNames?.includes(c.controlName),
          ),
      )
      ?.map((s) => s.id)
  }, [claimData, claimTemplateFilteredByPermissions])

  const disableSelectButton =
    isLoading || isRefetching || isAdwovareSyncing || isClaimUpdating

  useEffect(() => {
    showAdvoWareChanges()
  }, [claimData])

  return (
    <CustomBackdrop open={open}>
      <Wrapper>
        <Header>
          <Box
            marginTop="18px"
            display="flex"
            flexDirection="column"
            gap="4px"
            width="100%"
          >
            <Box display="flex" gap="2px">
              <Error color="warning" />
              <Typography variant="regularBold" marginTop="1px">
                {t("claimConflictsToResolve")}
              </Typography>
            </Box>
            <Typography variant="regular" color={colors.black3}>
              <Trans
                i18nKey="claimConflictsDescription"
                components={[
                  <Typography variant="regularBold" key="1" />,
                  <Typography variant="regularBold" key="2" />,
                ]}
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={isSmallerThanSm ? "column" : "row"}
            gap="16px"
          >
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedVersion(undefined)
                onClose()
              }}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              disabled={!selectedVersion}
              loading={isAdwovareSyncing || isClaimUpdating}
              onClick={onClickResolve}
            >
              {t("resolve")}
            </LoadingButton>
          </Box>
        </Header>
        <SubHeader>
          <SubSection>
            <Typography variant="large">
              <Trans
                i18nKey="versionFromAdvoWare"
                components={[<Typography variant="largeBold" key="1" />]}
              />
            </Typography>
            <SelectButton
              color={
                selectedVersion === "advoWareVersion" ? "success" : "primary"
              }
              startIcon={
                selectedVersion === "advoWareVersion" ? <Check /> : null
              }
              onClick={() => {
                setSelectedVersion("advoWareVersion")
              }}
              disabled={disableSelectButton}
            >
              {t("select")}
            </SelectButton>
          </SubSection>
          <SubSection>
            <Typography variant="large">
              <Trans
                i18nKey="versionFromCrashMate"
                components={[<Typography variant="largeBold" key="1" />]}
              />
            </Typography>
            <SelectButton
              color={
                selectedVersion === "crashMateVersion" ? "success" : "primary"
              }
              startIcon={
                selectedVersion === "crashMateVersion" ? <Check /> : null
              }
              onClick={() => {
                setSelectedVersion("crashMateVersion")
              }}
              disabled={disableSelectButton}
            >
              {t("select")}
            </SelectButton>
          </SubSection>
        </SubHeader>
        <Box display="flex" flexDirection="column" overflow="auto">
          {(isLoading || isRefetching) && (
            <Box>
              <ProgressLine />
            </Box>
          )}

          <Body>
            <FormSection ref={form1Ref}>
              {!!claimData && !!claimTemplateFilteredByPermissions && (
                <SectionsList
                  claim={claimData}
                  template={claimTemplateFilteredByPermissions}
                  disabled
                  formMethods={methodsAdvoware}
                  isEditModeEnabled={false}
                  isComparisonPreview
                  conflictedSectionIds={conflictedSectionIds}
                  conflictedColor={colors.red2}
                />
              )}
            </FormSection>
            <FormSection ref={form2Ref}>
              {!!claimData && !!claimTemplateFilteredByPermissions && (
                <SectionsList
                  claim={claimData}
                  template={claimTemplateFilteredByPermissions}
                  disabled
                  formMethods={methodsCrashMate}
                  isEditModeEnabled={false}
                  isComparisonPreview
                  conflictedSectionIds={conflictedSectionIds}
                  conflictedColor={colors.green2}
                />
              )}
            </FormSection>
          </Body>
        </Box>
      </Wrapper>
    </CustomBackdrop>
  )
}
