import { TableCell, TableBody, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EmptyTableRow, NoDataTableRow } from "./styled"
import React from "react"
import { VehicleTableRow } from "./components"

interface IProps {
  rows?: IVehicle[]
  isRefetching?: boolean
  onEditVehicleClick?: (vehicle: IVehicle) => void
  onDeleteVehicleClick?: (vehicle: IVehicle) => void
}

export const VehiclesTableBody = (props: IProps) => {
  const { rows, isRefetching, onEditVehicleClick, onDeleteVehicleClick } = props
  const { t } = useTranslation()

  return (
    <TableBody>
      <EmptyTableRow>
        <TableCell colSpan={7} height="16px" />
      </EmptyTableRow>
      {!rows?.length && (
        <NoDataTableRow>
          <TableCell align="center" colSpan={7}>
            <Typography>{t("noData")}</Typography>
          </TableCell>
        </NoDataTableRow>
      )}
      {!!rows?.length &&
        rows.map((row, index) => (
          <React.Fragment key={index}>
            <VehicleTableRow
              isLoading={!!isRefetching}
              row={row}
              onEditClick={onEditVehicleClick}
              onDeleteClick={onDeleteVehicleClick}
            />
            <EmptyTableRow>
              <TableCell colSpan={7} height="16px" />
            </EmptyTableRow>
          </React.Fragment>
        ))}
    </TableBody>
  )
}
