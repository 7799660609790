import { useMemo, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Box, CircularProgress, Tooltip } from "@mui/material"
import {
  EDIT_CLAIM_PATHNAME_REGEX,
  colors,
  WORKFLOW_CONFIGURATOR_PATHNAME_REGEX,
  WORKFLOW_PREVIEW_PATHNAME_REGEX,
  canViewGroupDetails,
} from "../../../../../../../utils"
import { CompactSideBarItem, SideBarItem } from "./styled"
import { useAppContext } from "../../../../../../../contexts"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createClaimAPI } from "../../../../../../../services"
import SearchBar from "../../../../../../../components/SearchBar/SearchBar"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined"
import EditIcon from "@mui/icons-material/Edit"
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined"
import DashboardIcon from "@mui/icons-material/Dashboard"
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined"
import SearchIcon from "@mui/icons-material/Search"
import PolylineIcon from "@mui/icons-material/Polyline"
import FilterBAndWIcon from "@mui/icons-material/FilterBAndW"
import AllInbox from "@mui/icons-material/AllInbox"
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined"
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined"
interface IProps {
  isCompact?: boolean
  onSearchClick?: () => void
}

export const SideBar = (props: IProps) => {
  const { isCompact, onSearchClick } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const searchRef = useRef<any>(null)

  const client = useQueryClient()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const { state: appState } = useAppContext()

  const isProduction = process.env.REACT_APP_NAME === "PRODUCTION"
  const isStaging = process.env.REACT_APP_NAME === "STAGING"

  const { mutate: createClaim, isPending: isClaimCreating } = useMutation({
    mutationFn: () =>
      createClaimAPI({ body: {}, params: { groupId: appState?.groupId! } }),
    onSuccess: (response) => {
      client.setQueryData(["claim-details", response.id], response)
      navigate(`/claims/${response.id}`, {
        state: { isEditModeEnabled: true, shouldSetDefaultValues: true },
      })
    },
  })

  const hasAdministrationPermission = useMemo(
    () =>
      !!user?.permissions?.some(
        (permission) =>
          permission?.scope === "Group" &&
          permission?.objectId === appState.groupId &&
          permission?.action === "GROUP_DETAILS" &&
          permission?.access === "Delete",
      ),
    [user, appState.groupId],
  )

  const hasPermissionForReporting = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) => p.action === "REPORTING" && p.access === "Delete",
      ),
    [user],
  )

  const hasPermissionForVehicleManagement = useMemo(
    () =>
      !!user?.permissions?.some(
        (p) =>
          p.action === "CAR_MANAGEMENT" &&
          p.access === "Delete" &&
          user.companyType === "CarPool",
      ),
    [user],
  )

  const hasOrganizationPermission = useMemo(
    () =>
      !!user?.permissions?.some(
        (permission) =>
          permission?.action === "ORGANIZATION_DETAILS" &&
          permission?.access === "Delete",
      ),
    [user, appState.groupId],
  )

  const isSuperAdmin = useMemo(() => canViewGroupDetails(user), [user])

  if (isCompact) {
    return (
      <Box
        width="80px"
        bgcolor={colors.secondary}
        borderRight={`1px solid ${colors.gray5}`}
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop="24px"
        className="scroll"
      >
        <Tooltip placement="right" title={t("search")}>
          <Box component="span">
            <CompactSideBarItem
              onClick={() => {
                onSearchClick?.()

                setTimeout(() => {
                  searchRef?.current?.focus()
                }, 200)
              }}
            >
              <SearchIcon fontSize="medium" />
            </CompactSideBarItem>
          </Box>
        </Tooltip>

        <Box marginTop="12px" />

        <Tooltip placement="right" title={t("dashboard")}>
          <Box component="span">
            <CompactSideBarItem
              onClick={() => navigate("../dashboard")}
              selected={pathname === "/dashboard"}
            >
              <DashboardIcon fontSize="medium" />
            </CompactSideBarItem>
          </Box>
        </Tooltip>

        <Box marginTop="12px" />

        <Tooltip placement="right" title={t("claimListing")}>
          <Box component="span">
            <CompactSideBarItem
              onClick={() => navigate("../claims")}
              selected={pathname === "/claims"}
            >
              <DescriptionOutlinedIcon fontSize="medium" />
            </CompactSideBarItem>
          </Box>
        </Tooltip>

        {EDIT_CLAIM_PATHNAME_REGEX.test(pathname) && (
          <Box marginTop="12px">
            <Tooltip placement="right" title={t("claim")}>
              <Box component="span">
                <CompactSideBarItem selected>
                  <EditIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>
          </Box>
        )}

        <Box marginTop="12px" />

        <Tooltip placement="right" title={t("newClaim")}>
          <Box component="span">
            <CompactSideBarItem
              onClick={() => createClaim()}
              selected={pathname === "/claim/new"}
              disabled={isClaimCreating}
            >
              <NoteAddOutlinedIcon fontSize="medium" />
            </CompactSideBarItem>
          </Box>
        </Tooltip>
        {hasOrganizationPermission && (
          <>
            <Box marginTop="12px" />
            <Tooltip placement="right" title={t("organizationManagement")}>
              <Box component="span">
                <CompactSideBarItem
                  onClick={() => navigate("../organization-management")}
                  selected={pathname === "/organization-management"}
                >
                  <CorporateFareOutlinedIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>
          </>
        )}
        {(isSuperAdmin || hasAdministrationPermission) && (
          <>
            <Box marginTop="12px" />
            <Tooltip placement="right" title={t("groupManagement")}>
              <Box component="span">
                <CompactSideBarItem
                  onClick={() => navigate("../group-management")}
                  selected={pathname === "/group-management"}
                >
                  <GroupsOutlinedIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>

            <Box marginTop="12px" />
            <Tooltip placement="right" title={t("userManagement")}>
              <Box component="span">
                <CompactSideBarItem
                  onClick={() => navigate("../user-management")}
                  selected={pathname === "/user-management"}
                >
                  <ManageAccountsOutlinedIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>
          </>
        )}

        {hasPermissionForVehicleManagement && (
          <>
            <Box marginTop="12px" />
            <Tooltip placement="right" title={t("carManagement")}>
              <Box component="span">
                <CompactSideBarItem
                  onClick={() => navigate("../car-management")}
                  selected={pathname === "/car-management"}
                >
                  <DirectionsCarFilledOutlinedIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>
          </>
        )}

        {hasPermissionForReporting && (
          <>
            <Box marginTop="12px" />
            <Tooltip placement="right" title={t("reporting")}>
              <Box component="span">
                <CompactSideBarItem
                  onClick={() => navigate("../reporting")}
                  selected={pathname === "/reporting"}
                >
                  <AssessmentOutlinedIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>
          </>
        )}

        {!isProduction && !isStaging && (
          <>
            <Box marginTop="12px">
              <Tooltip placement="right" title={t("globalInbox")}>
                <Box component="span">
                  <CompactSideBarItem
                    onClick={() => navigate("../global-inbox")}
                    selected={pathname === "/global-inbox"}
                  >
                    <AllInbox fontSize="medium" />
                  </CompactSideBarItem>
                </Box>
              </Tooltip>
            </Box>

            <Box marginTop="12px">
              <Tooltip placement="right" title={t("automation")}>
                <Box component="span">
                  <CompactSideBarItem
                    onClick={() => navigate("../workflow-overview")}
                    selected={pathname === "/workflow-overview"}
                  >
                    <TaskAltOutlinedIcon fontSize="medium" />
                  </CompactSideBarItem>
                </Box>
              </Tooltip>
            </Box>

            <Box marginTop="12px" />

            <Tooltip placement="right" title={t("definitionsListing")}>
              <Box component="span">
                <CompactSideBarItem
                  onClick={() => navigate("../workflow-definitions")}
                  selected={pathname === "/workflow-definitions"}
                >
                  <PolylineIcon fontSize="medium" />
                </CompactSideBarItem>
              </Box>
            </Tooltip>
          </>
        )}
      </Box>
    )
  }

  return (
    <Box
      width="255px"
      bgcolor={colors.secondary}
      borderRight={`1px solid ${colors.gray5}`}
      display="flex"
      flexDirection="column"
      className="scroll"
    >
      <Box padding="16px">
        <SearchBar inputRef={searchRef} />
      </Box>

      <SideBarItem
        fullWidth
        variant="text"
        onClick={() => navigate("../dashboard")}
        selected={pathname === "/dashboard"}
      >
        <DashboardIcon fontSize="inherit" />
        {t("dashboard")}
      </SideBarItem>

      <SideBarItem
        fullWidth
        variant="text"
        onClick={() => navigate("../claims")}
        selected={pathname === "/claims"}
      >
        <DescriptionOutlinedIcon fontSize="inherit" />
        {t("claimListing")}
      </SideBarItem>

      {EDIT_CLAIM_PATHNAME_REGEX.test(pathname) && (
        <SideBarItem fullWidth variant="text" selected>
          <EditIcon fontSize="inherit" />
          {t("editClaim")}
        </SideBarItem>
      )}

      <SideBarItem
        fullWidth
        variant="text"
        onClick={() => createClaim()}
        selected={pathname === "/claim/new"}
        loading={isClaimCreating}
        endIcon={isClaimCreating ? <CircularProgress size="small" /> : <></>}
        loadingPosition="end"
      >
        <NoteAddOutlinedIcon fontSize="inherit" />
        {t("newClaim")}
      </SideBarItem>
      {hasPermissionForVehicleManagement && (
        <SideBarItem
          fullWidth
          variant="text"
          onClick={() => navigate("../vehicle-management")}
          selected={pathname === "/vehicle-management"}
        >
          <DirectionsCarFilledOutlinedIcon fontSize="inherit" />
          {t("vehicleManagement")}
        </SideBarItem>
      )}
      {hasOrganizationPermission && (
        <SideBarItem
          fullWidth
          variant="text"
          onClick={() => navigate("../organization-management")}
          selected={pathname === "/organization-management"}
        >
          <CorporateFareOutlinedIcon fontSize="inherit" />
          {t("organizationManagement")}
        </SideBarItem>
      )}
      {(isSuperAdmin || hasAdministrationPermission) && (
        <>
          <SideBarItem
            fullWidth
            variant="text"
            onClick={() => navigate("../group-management")}
            selected={pathname === "/group-management"}
          >
            <GroupsOutlinedIcon fontSize="inherit" />
            {t("groupManagement")}
          </SideBarItem>
          <SideBarItem
            fullWidth
            variant="text"
            onClick={() => navigate("../user-management")}
            selected={pathname === "/user-management"}
          >
            <ManageAccountsOutlinedIcon fontSize="inherit" />
            {t("userManagement")}
          </SideBarItem>
        </>
      )}

      {hasPermissionForReporting && (
        <SideBarItem
          fullWidth
          variant="text"
          onClick={() => navigate("../reporting")}
          selected={pathname === "/reporting"}
        >
          <AssessmentOutlinedIcon fontSize="inherit" />
          {t("reporting")}
        </SideBarItem>
      )}

      {!isProduction && !isStaging && (
        <>
          <SideBarItem
            fullWidth
            variant="text"
            onClick={() => navigate("../global-inbox")}
            selected={pathname === "/global-inbox"}
          >
            <AllInbox fontSize="inherit" />
            {t("globalInbox")}
          </SideBarItem>
          <SideBarItem
            fullWidth
            variant="text"
            onClick={() => navigate("../workflow-overview")}
            selected={pathname === "/workflow-overview"}
          >
            <TaskAltOutlinedIcon fontSize="inherit" />
            {t("automation")}
          </SideBarItem>
          <SideBarItem
            fullWidth
            variant="text"
            onClick={() => navigate("../workflow-definitions")}
            selected={pathname === "/workflow-definitions"}
          >
            <PolylineIcon fontSize="inherit" />
            {t("definitionsListing")}
          </SideBarItem>

          {WORKFLOW_CONFIGURATOR_PATHNAME_REGEX.test(pathname) && (
            <SideBarItem
              fullWidth
              variant="text"
              selected={pathname.includes("/workflow-configurator")}
            >
              <FilterBAndWIcon fontSize="inherit" />
              {t("configurator")}
            </SideBarItem>
          )}

          {WORKFLOW_PREVIEW_PATHNAME_REGEX.test(pathname) && (
            <SideBarItem
              fullWidth
              variant="text"
              selected={pathname.includes("/workflow-preview")}
            >
              <FilterBAndWIcon fontSize="inherit" />
              {t("preview")}
            </SideBarItem>
          )}
        </>
      )}
    </Box>
  )
}
