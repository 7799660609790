import { colors } from "../../../../../utils"

export const getStatusColor = (status: string) => {
  switch (status) {
    case "Draft":
      return colors.yellow2
    case "Active":
      return colors.green
    case "Completed":
      return colors.gray3
    default:
      return colors.gray3
  }
}

export const convertStatus = (status: TStatus) => {
  switch (status) {
    case "Active":
    case "Duplicate":
    case "RequestedToClose":
    case "WaitingForQuantification":
    case "PaymentReceived":
    case "PaymentReminded":
    case "PaymentRequested":
    case "TechnicalError":
    case "Submitted":
    case "WaitingForRepairInvoice":
    case "WaitingForMandantFeedback":
    case "WaitingForCaseFileAccess":
    case "WaitingForOtherFeedback":
      return "Active"
    case "InCreation":
      return "Draft"
    case "Closed":
      return "Completed"
    default:
      return ""
  }
}

export const getProcessingStatus = (status: TStatus) => {
  switch (status) {
    case "WaitingForQuantification":
      return status
    case "PaymentReceived":
      return status
    case "PaymentRequested":
      return status
    case "PaymentReminded":
      return status
    default:
      return "-"
  }
}

export const getProcessingStatusUpdate = (status: TStatus) => {
  switch (status) {
    case "WaitingForQuantification":
      return 6
    case "PaymentReceived":
      return 7
    case "PaymentRequested":
      return 7
    case "PaymentReminded":
      return 6
    default:
      return 0
  }
}
