import { Dialog, styled, Box } from "@mui/material"
export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
    height: 90%;
    max-width: 1200px;
    max-height: 80%;

    @media (max-width: 600px) {
      max-height: 100%;
    }
  }
`

export const ContentWrapper = styled(Box)`
  display: flex;
  height: 100%;
`
