import { TableRow, styled } from "@mui/material"
import { colors } from "../../../../../../utils"

export const CustomRow = styled(TableRow)({
  backgroundColor: colors.secondary,
  borderRadius: "0px !important",
  borderBottom: `2px solid ${colors.white}`,

  "& .MuiTableCell-root": {
    backgroundColor: colors.secondary + " !important",

    borderTop: `1px solid ${colors.gray13}`,
    borderBottom: `1px solid ${colors.gray13}`,

    "&:first-of-type": {
      borderLeft: `1px solid ${colors.gray13}`,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-of-type": {
      borderRight: `1px solid ${colors.gray13}`,
      borderLeft: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
})
