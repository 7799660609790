import { Table, TableContainer, styled } from "@mui/material"
import { colors } from "../../../../utils"

export const TableContainerStyled = styled(TableContainer)({
  borderTop: 0,
  borderBottom: 0,
  padding: "0px 24px",

  overflowY: "initial",
})

export const TableStyled = styled(Table)(() => ({
  borderCollapse: "separate",

  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      height: "auto",
    },
  },

  "& > .MuiTableFooter-root": {
    "& .MuiTableCell-root": {
      backgroundColor: colors.secondary,
      border: `1px solid ${colors.gray13}`,
      borderRadius: "10px",
    },
  },
}))
