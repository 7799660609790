import {
  TableCell,
  TableSortLabel,
  useMediaQuery,
  Grid,
  TableHead,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { TableRowStyled } from "./styled"

interface IHeadCell {
  id: keyof IVehicle | any
  label?: string
  width: string
  subFields?: Array<{
    id: keyof IVehicle
    label: string
  }>
  align?: any
}

interface IProps {
  order?: TOrder
  orderBy?: any
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof IVehicle,
  ) => void
  onHeaderClick?: () => void
}

export const VehiclesTableHead = (props: IProps) => {
  const { order, orderBy, onRequestSort, onHeaderClick } = props
  const { t } = useTranslation()
  const isGreaterThan1420 = useMediaQuery("(min-width: 1420px)")

  const headCells: readonly IHeadCell[] = [
    {
      id: "plate",
      width: "16%",
      subFields: [
        {
          id: "numberPlate",
          label: t("officialRegistrationNumber"),
        },
        { id: "vin", label: t("chasisNumber") },
      ],
    },
    {
      id: "manu",
      width: "16%",
      subFields: [
        { id: "manufacturer", label: t("manufacturer") },
        {
          id: "manufacturerType",
          label: t("manufacturerType"),
        },
      ],
    },
    {
      id: "vehicle",
      width: "16%",
      subFields: [
        { id: "vehicleType", label: t("vehicleType") },
        {
          id: "registrationDate",
          label: t("initialRegistration"),
        },
      ],
    },
    {
      id: "insurance",
      width: "16%",
      subFields: [
        { id: "insuranceCompany", label: t("insurer") },
        { id: "group", label: t("group") },
      ],
    },
    {
      id: "created",
      width: "16%",
      subFields: [
        { id: "createdAt", label: t("createdDate") },
        { id: "createdBy", label: t("createdBy") },
      ],
    },
    {
      id: "updated",
      width: "16%",
      subFields: [
        { id: "lastUpdatedAt", label: t("editedDate") },
        { id: "lastUpdatedBy", label: t("editedBy") },
      ],
    },
  ]

  return (
    <TableHead onClick={onHeaderClick}>
      <TableRowStyled>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="none"
            width={headCell.width}
            className={isGreaterThan1420 ? "word-break-all" : ""}
            style={{
              minWidth: isGreaterThan1420 ? headCell.width : "200px",
            }}
          >
            <Grid container direction="column">
              {!headCell?.subFields?.length ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(event) => onRequestSort?.(event, headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                <Grid container direction="column">
                  {headCell.subFields.map((subField: any) => (
                    <Grid item key={subField.id}>
                      <TableSortLabel
                        active={orderBy === subField.id}
                        direction={orderBy === subField.id ? order : "asc"}
                        onClick={(event) => onRequestSort?.(event, subField.id)}
                      >
                        {subField.label}
                      </TableSortLabel>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </TableCell>
        ))}
        <TableCell width="4%" />
      </TableRowStyled>
    </TableHead>
  )
}
