import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Skeleton,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getStatusChangesAPI } from "../../../../../../services"
import { getStatusDotColor } from "../../../../../../utils"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { CustomRow, CustomTableCell, CustomTableContainer } from "../../styled"
import { type IFilters } from "../../../../DashboardPage"

const statusMapping: Record<number, TStatus> = {
  0: "InCreation",
  1: "Active",
  2: "Duplicate",
  3: "RequestedToClose",
  4: "Closed",
  5: "WaitingForQuantification",
  6: "PaymentRequested",
  7: "PaymentReminded",
  8: "PaymentReceived",
  9: "Submitted",
  10: "TechnicalError",
  11: "WaitingForRepairInvoice",
  12: "WaitingForMandantFeedback",
  13: "WaitingForCaseFileAccess",
  14: "WaitingForOtherFeedback",
}

export const StatusChanges: React.FC<{
  claimId: string
  filters?: IFilters
}> = ({ claimId, filters }) => {
  const { t } = useTranslation()
  const {
    data: statusChanges,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["statusChanges", claimId, filters?.from, filters?.to],
    queryFn: () =>
      getStatusChangesAPI({ claimId, from: filters?.from, to: filters?.to }),
    refetchOnMount: true,
  })

  return (
    <CustomTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell>{t("oldValue")}</CustomTableCell>
            <CustomTableCell>{t("newValue")}</CustomTableCell>
            <CustomTableCell>{t("changesBy")}</CustomTableCell>
            <TableCell>{t("date")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading || isRefetching ? (
            <>
              {[...Array(3)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : statusChanges?.length ? (
            statusChanges.map((change, index) => (
              <CustomRow key={index}>
                <CustomTableCell>
                  <Box display="flex" alignItems="center">
                    <Box
                      width="8px"
                      height="8px"
                      bgcolor={getStatusDotColor(
                        statusMapping[change.oldValue.status],
                      )}
                      borderRadius="50%"
                      mr="4px"
                    />
                    {statusMapping[change.oldValue.status]}
                  </Box>
                </CustomTableCell>
                <CustomTableCell>
                  <Box display="flex" alignItems="center">
                    <Box
                      width="8px"
                      height="8px"
                      bgcolor={getStatusDotColor(
                        statusMapping[change.newValue.status],
                      )}
                      borderRadius="50%"
                      mr="4px"
                    />
                    {statusMapping[change.newValue.status]}
                  </Box>
                </CustomTableCell>
                <CustomTableCell>{change.changesBy}</CustomTableCell>
                <TableCell>
                  {dayjs(change.modifiedAt).format("DD.MM.YYYY hh:mm")}
                </TableCell>
              </CustomRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {t("noData")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  )
}
