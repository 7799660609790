/* eslint-disable quotes */
import { type Edge, type Node, Position } from "reactflow"
import dagre from "@dagrejs/dagre"
import { colors } from "../../../utils"
import {
  ACTION_METHODS,
  CORE_METHODS,
  EDefinitionType,
  TRIGGER_METHODS,
} from "./constants"
import {
  AccountTreeOutlined,
  AltRoute,
  Animation,
  Assignment,
  Bolt,
  Calculate,
  CalendarToday,
  CallSplit,
  Diamond,
  ForwardToInbox,
  Http,
  Inbox,
  MarkEmailUnread,
  Outbox,
  OutlinedFlag,
  PausePresentation,
  PostAdd,
  QueryBuilder,
  Terminal,
  TextSnippet,
  BackupTable,
} from "@mui/icons-material"
import StorageIcon from "@mui/icons-material/Storage"
import DescriptionIcon from "@mui/icons-material/Description"

type IdCategoryTypes =
  | "D"
  | "T"
  | "A"
  | "C"
  | "BR"
  | "BA"
  | "BT"
  | "BF"
  | "B1"
  | "B2"
  | any

export const generateId = (
  idType: "step" | "edge",
  category: IdCategoryTypes,
  action: string,
  sourceStep?: string,
  targetStep?: string,
): string => {
  if (idType === "step") {
    return `$${category}/${action}${Math.floor(10000 + Math.random() * 90000)}`
  }

  if (idType === "edge" && sourceStep && targetStep) {
    return `€${category}:${sourceStep}-${targetStep}`
  }

  return ""
}

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const nodeWidth = 500
  const nodeHeight = 150

  dagreGraph.setGraph({ rankdir: "TB" })

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Bottom
    node.sourcePosition = Position.Top

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }
  })

  return { nodes, edges }
}

const typeMap: Record<number, string> = {
  0: "trigger",
  1: "action",
  2: "core",
  3: "dropzone",
  4: "posteingang",
  5: "postausgang",
  6: "wiedervorlage",
  7: "genericstep",
}
const typeMapReverse: Record<string, number> = {
  trigger: 0,
  action: 1,
  core: 2,
  dropzone: 3,
  posteingang: 4,
  postausgang: 5,
  wiedervorlage: 6,
  genericstep: 7,
}

const createNodeFromStep = (
  step: IWorkflowStep,
  type: string,
  parentId: string,
): Node => ({
  id: step.id,
  type,
  data: {
    type: typeMap[step.type],
    action: step.stepType,
    label: step.name ?? step.id,
    nextStepId: step.nextStepId,
    id: step.id,
    icon: step.icon,
    inputs: step?.inputs,
    outputs: step?.outputs,
    do: [],
    parentId,
  },
  position: { x: 0, y: 0 },
})

export const transformStepToNode = (
  step: IWorkflowStep,
  parentId: string,
): Node[] => {
  const nodes: Node[] = []

  const dropzoneId = generateId("step", "D", "DZ")
  step.nextStepId = step.nextStepId === "" ? dropzoneId : step.nextStepId

  if (step.nextStepId.includes("D/DZ")) {
    nodes.push({
      id: dropzoneId,
      type: "dropzoneCard",
      data: { id: dropzoneId, title: "step", parentId: step.id },
      position: { x: 0, y: 0 },
    })
  }

  if (step.stepType === "If") {
    const ifStepNode = createNodeFromStep(step, "boolCard", parentId)
    nodes.push(ifStepNode)

    if (step.do?.length > 0) {
      step.do[0].forEach((trueBranch: IWorkflowStep) => {
        nodes.push(...transformStepToNode(trueBranch, step.id))
      })
    } else {
      const trueBranchDropzoneId = generateId("step", "D", "DZ")
      nodes.push({
        id: trueBranchDropzoneId,
        type: "dropzoneCard",
        data: { id: trueBranchDropzoneId, title: "step", parentId: step.id },
        position: { x: 0, y: 0 },
      })
    }

    return nodes
  }

  const defaultNode = createNodeFromStep(step, "actionCard", parentId)
  nodes.push(defaultNode)

  return nodes
}

const createStepFromNode = (node: Node, edges: Edge[]) => {
  const nextStepId = edges.find((edge) => edge.source === node.id)?.target ?? ""
  const checkedNextId = nextStepId.includes("$D/DZ") ? "" : nextStepId

  const step: IWorkflowStep = {
    id: node.id,
    type: typeMapReverse[node.data.type],
    stepType: node.data?.action as string,
    icon: node.data.icon,
    inputs: node.data.inputs,
    cancelCondition: null,
    errorBehavior: null,
    compensateWith: [],
    saga: false,
    nextStepId: checkedNextId,
    outputs: node.data.outputs,
    do: [],
    retryInterval: null,
    name: node.data.label,
    userInteraction: false,
  }

  if (node.type === "dropzoneCard") {
    step.type = 3
    step.icon = ""
    step.inputs = null
    step.outputs = null
    step.selectNextStep = null
    step.do = null
  }

  return step
}

export const transformNodeToStep = (
  nodes: Node[],
  edges: Edge[],
): IWorkflowStep[] => {
  const steps: IWorkflowStep[] = []

  nodes.forEach((node) => {
    if (
      node.id.includes("$D/DZ") ||
      !!edges.find((e) => e.target === node.id)?.id.includes("BT")
    )
      return

    if (node.id.includes("$C/IF")) {
      const ifStep = createStepFromNode(node, edges)

      const trueBranch = nodes.find(
        (n) =>
          edges.find((e) => e.source === node.id && e.id.includes("€BT:"))
            ?.target === n.id,
      )

      if (trueBranch) ifStep.do = [[createStepFromNode(trueBranch, edges)]]

      const falseBranch = nodes.find(
        (n) =>
          edges.find((e) => e.source === node.id && e.id.includes("€BF:"))
            ?.target === n.id,
      )

      if (falseBranch) ifStep.nextStepId = falseBranch?.id ?? ""

      steps.push(ifStep)
    } else {
      steps.push(createStepFromNode(node, edges))
    }
  })

  return steps
}

const createEdge = (
  source: Node | undefined,
  target: Node,
  edgeType: string,
) => {
  const label = () => {
    switch (edgeType) {
      case "BT":
        return "True"
      case "BF":
        return "False"
      default:
        return ""
    }
  }

  const generatedId = generateId("edge", edgeType, "", source?.id, target?.id)
  const newEdge: Edge = {
    id: generatedId,
    source: source?.id!,
    target: target?.id,
    label: label(),
    data: { source: source?.id },
    type: edgeType === "BA" ? "addButtonEdge" : "",
    style: { stroke: colors.gray3 },
  }

  return newEdge
}

export const createEdges = (nodes: Node[]) => {
  const edges: Edge[] = []
  nodes.forEach((node) => {
    let label = "BR"

    const parent =
      nodes.find((n) => n.id === node.data?.parentId) ||
      nodes.find((n) => n.data?.nextStepId === node.id)

    if (node.type !== "dropzoneCard") {
      label = "BA"
    }
    if (parent?.data?.action === "If" && parent?.data?.nextStepId === node.id) {
      label = "BF"
    } else if (parent?.data?.action === "If") {
      label = "BT"
    }

    if (parent !== undefined) edges.push(createEdge(parent, node, label))
  })

  return edges
}

export const getCategoryItems = (category: string): IMethodItem[] => {
  switch (category) {
    case "triggers":
      return TRIGGER_METHODS
    case "actions":
      return ACTION_METHODS
    case "core":
      return CORE_METHODS
    default:
      return []
  }
}

export const getNodeIcon = (icon: string, type: string) => {
  switch (type) {
    case "trigger":
      switch (icon) {
        case "forwardToInbox":
          return <ForwardToInbox />
        case "calendarToday":
          return <CalendarToday />
        case "textSnippet":
          return <TextSnippet />
        case "claim":
          return <Assignment />
        case "httpRequest":
          return <Http />
        case "incomingEmail":
          return <MarkEmailUnread />
        default:
          return <Bolt />
      }
    case "posteingang":
    case "postausgang":
    case "wiedervorlage":
    case "genericstep":
    case "action":
      switch (icon) {
        case "outgoingEmail":
          return <Outbox />
        case "inbox":
          return <Inbox />
        case "postAdd":
          return <PostAdd />
        case "data":
          return <DescriptionIcon />
        case "table":
          return <BackupTable />
        case "database":
          return <StorageIcon />
        default:
          return <Animation />
      }
    case "core":
      switch (icon) {
        case "pausePresentation":
          return <PausePresentation />
        case "altRoute":
          return <AltRoute />
        case "terminal":
          return <Terminal />
        case "splitArrows":
          return <CallSplit />
        case "calculate":
          return <Calculate />
        case "queryBuilder":
          return <QueryBuilder />
        case "accountTree":
          return <AccountTreeOutlined />
        case "outlinedFlag":
          return <OutlinedFlag />
        default:
          return <Diamond />
      }
    default:
      return null
  }
}

export const transformDraggedItem = (active: any): Partial<IMethodItem> => {
  const draggedItem = active.data.current?.item
  return {
    shorthand: draggedItem?.shorthand,
    action: draggedItem?.action,
    label: draggedItem?.label,
    type: draggedItem?.type,
    icon: draggedItem?.icon,
  }
}

export const parseInputValue = (value: any) => {
  if (
    typeof value === "string" &&
    value?.length &&
    ((value.startsWith("'") && value.endsWith("'")) ||
      (value.startsWith('"') && value.endsWith('"')))
  ) {
    return value.slice(1, value.length - 1)
  }

  return value
}

export const mapInputValue = (
  value: any,
  stepType: TStepTypes,
  controlName: string,
) => {
  if (
    typeof value === "object" ||
    (stepType === "If" && controlName === "Condition")
  ) {
    return value
  } else if (typeof value === "string") {
    return `'${value}'`
  }

  return value
}

export const createNewIds = (
  over: any,
  transformedItem: Partial<IMethodItem>,
) => {
  const category = (() => {
    switch (transformedItem?.type) {
      case "trigger":
        return "T"
      case "action":
        return "A"
      case "core":
        return "C"
      default:
        return "A"
    }
  })()
  // If over.id, which is the dropzone id, contains '-B{some number}' and ends with '-dropzone' then it is the dropzone of a branch
  // in which case, the id of the transformed item should be changed to '{parent id}-B{some number signifying branch order}
  const newId = generateId("step", category, transformedItem?.shorthand!)

  return newId
}

export const addRegularNode = (
  nodes: Node[],
  edges: Edge[],
  transformedItem: Partial<IMethodItem>,
  newId: string,
  over: any,
): { transformedNodes: Node[]; transformedEdges: Edge[] } => {
  const edgeWithNextNode = edges.find((edge) => edge.source === over?.id)
  const nextNode = nodes.find((node) => node.id === edgeWithNextNode?.target)

  // If a node is added in the middle, there should be a node beneath it, otherwise it is the last node
  if (nextNode) {
    const updatedNodes = nodes.map((node) =>
      node.id === over?.id
        ? {
            ...node,
            id: newId,
            type: "actionCard",
            data: {
              ...transformedItem,
            },
          }
        : node,
    )

    const updatedEdges = edges.map((edge) => {
      if (edge.target === over?.id) {
        const edgeId = generateId(
          "edge",
          edge.id.includes("€BT:")
            ? "BT"
            : edge.id.includes("€BF:")
            ? "BF"
            : "BA",
          "",
          edge.source,
          newId,
        )
        return {
          ...edge,
          id: edgeId,
          target: newId,
          type: edgeId.includes("€BA:") ? "addButtonEdge" : "",
        }
      }
      if (edge.source === over?.id)
        return {
          ...edge,
          id: generateId(
            "edge",
            edge.label === "True"
              ? "BT"
              : edge.label === "False"
              ? "BF"
              : edge.label
              ? "BR"
              : "BA",
            "",
            newId,
            edge.target,
          ),
          source: newId,
          type: edge.label ? "" : "addButtonEdge",
        }

      return edge
    })

    return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
  }

  const dropzoneId = generateId("step", "D", "DZ")

  const updatedNodes = nodes
    .concat({
      id: dropzoneId,
      type: "dropzoneCard",
      data: { id: dropzoneId, title: "step" },
      position: { x: 0, y: 0 },
    })
    .map((node) => {
      if (node.id === over?.id)
        return {
          ...node,
          id: newId,
          type: "actionCard",
          data: {
            ...transformedItem,
          },
        }
      return node
    })

  const generatedEdgeIdDropzone = generateId(
    "edge",
    "BR",
    "",
    newId,
    dropzoneId,
  )

  const updatedEdges = edges
    .map((edge) => {
      const edgeId = generateId(
        "edge",
        edge.label === "True"
          ? "BT"
          : edge.label === "False"
          ? "BF"
          : edge.label
          ? "BR"
          : "BA",
        "",
        edge.source,
        newId,
      )
      return edge.target === over?.id
        ? {
            ...edge,
            id: edgeId,
            target: newId,
            type: edgeId.includes("€BA:") ? "addButtonEdge" : "",
          }
        : edge
    })
    .concat({
      id: generatedEdgeIdDropzone,
      source: newId,
      target: dropzoneId,
      type: "",
    })

  return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
}

export const mapNodeItem = (node: Node): INodeItem => {
  return {
    id: node.id,
    ...node.data,
  }
}

export const addBranchedNode = (
  nodes: Node[],
  edges: Edge[],
  transformedItem: Partial<IMethodItem>,
  newId: string,
  over: any,
  branches: number,
): { transformedNodes: Node[]; transformedEdges: Edge[] } => {
  // If you are adding a Decide step, attach this to data.selectNextStep
  const nextStepNodeArray = []
  const nextStepEdgeArray = []

  const isEdgeFromIfStep = branches === 2

  for (let i = 1; i <= branches; i++) {
    const dropzoneId = generateId("step", "D", "DZ")
    const edgeId = isEdgeFromIfStep
      ? generateId("edge", i === 1 ? "BT" : "BF", "", newId, dropzoneId)
      : generateId("edge", `B${i}`, "", newId, dropzoneId)

    // Add branch nodes
    nextStepNodeArray.push({
      id: dropzoneId,
      type: "dropzoneCard",
      data: { id: dropzoneId, title: "step" },
      position: { x: 0, y: 0 },
    })

    // Add branch edges
    nextStepEdgeArray.push({
      id: edgeId,
      source: newId,
      target: dropzoneId,
      label: i === 1 ? "True" : "False",
      type: "",
    })
  }

  const nextNodeEdge = edges.find((edge) => edge.source === over?.id)
  const nextNode = nodes.find((node) => node.id === nextNodeEdge?.target)

  // If a node is added in the middle, there should be lower node connections
  if (nextNode) {
    const updatedNodes = nodes
      .concat([
        ...nextStepNodeArray.slice(1), // Remove the first branch node, since it was replaced with the target node
      ])
      .map((node) => {
        // Replace the 'Add new step' id with the node id
        if (node.id === over?.id) {
          return {
            ...node,
            id: newId,
            type: "actionCard",
            data: {
              ...transformedItem,
              id: newId,
            },
          }
        }

        const dropzoneId = generateId("step", "D", "DZ")

        // If the node beneath is the last node, it has a dropzone. Update that too
        if (node.id === `${nextNode.id}-dropzone`) {
          return {
            ...node,
            id: dropzoneId,
            data: { id: dropzoneId, title: "step" },
          }
        }

        return node
      })

    const updatedEdges = edges
      .concat([
        ...nextStepEdgeArray.slice(1), // Remove the first branch edge, since it was replaced with the target edge
      ])
      .map((edge) => {
        // Update the upper edge so that it connects the upper node to our branch node (parent)
        if (edge.target === over?.id) {
          const generatedId = generateId(
            "edge",
            edge.label === "True" ? "BT" : edge.label === "False" ? "BF" : "BA",
            "",
            edge.source,
            newId,
          )
          return {
            ...edge,
            id: generatedId,
            target: newId,
            type: generatedId.includes("€BA:") ? "addButtonEdge" : "",
          }
        }

        // Update the lower edge so that it connects the lower node to the first branch of our new node
        if (edge.source === over?.id) {
          return {
            ...edge,
            id: generateId("edge", "BT", "", newId, nextNode.id),
            source: newId,
            target: nextNode.id,
            label: "True",
          }
        }
        // Update the edge connected to the lower node
        if (edge.source === nextNode.id) {
          return {
            ...edge,
            id: generateId(
              "edge",
              edge.label === "True"
                ? "BT"
                : edge.label === "False"
                ? "BF"
                : edge.target.includes("$D/DZ")
                ? "BR"
                : "BA",
              "",
              newId,
              edge.target,
            ),
            source: nextNode.id,
            type: "",
          }
        }
        return edge
      })

    return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
  }

  const updatedNodes = nodes.concat(nextStepNodeArray).map((node) =>
    node.id === over?.id
      ? {
          ...node,
          id: newId,
          type: "actionCard",
          data: {
            ...transformedItem,
          },
        }
      : node,
  )

  const updatedEdges = edges.concat(nextStepEdgeArray).map((edge) => {
    const updatedId = generateId(
      "edge",
      edge.label === "True"
        ? "BT"
        : edge.label === "False"
        ? "BF"
        : newId.includes("$D/DZ")
        ? "BR"
        : "BA",
      "",
      edge.source,
      newId,
    )
    return edge.target === over?.id
      ? {
          ...edge,
          id: updatedId,
          target: newId,
          type: updatedId.includes("€BA:") ? "addButtonEdge" : "",
        }
      : edge
  })

  return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
}

export const getDefinitionTypeName = (type: EDefinitionType) => {
  switch (type) {
    case EDefinitionType.User:
      return "user"
    case EDefinitionType.Custom:
      return "custom"
    case EDefinitionType.System:
      return "system"
    default:
      return ""
  }
}
