import { Box, styled, Tab, Button } from "@mui/material"
import { colors } from "../../utils"

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.white2};
  overflow: hidden;
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  min-height: 48px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray4};
  position: relative;

  @media (max-width: 700px) {
    justify-content: space-between;
    align-items: flex-start;
    min-height: 100px;
  }
`

export const InformationBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 700px) {
    padding-top: 10px;
  }
`

export const TabsWrapper = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`

export const CustomTab = styled(Tab)`
  font-size: 14px;
`

export const StatusWrapper = styled(Box)`
  @media (max-width: 700px) {
    padding-top: 10px;
  }
`

export const RerunButton = styled(Button)`
  width: 30px;
  height: 27.5px;
  border-radius: 15px;
`
